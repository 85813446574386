import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { useTranslation } from 'react-i18next';

function HowItWork() {
  const { t } = useTranslation();

  const list = [
    {
      icon: "/images/user.png",
      title: t('howItWork.free'),
      text: t('howItWork.accountOpening'),
    },
    {
      icon: "/images/invest.png",
      title: t('howItWork.commissionFromZero'),
      text: t('howItWork.commissionExplanation'),
    },
    {
      icon: "/images/money.png",
      title: t('howItWork.techSupport24/7'),
      text: t('howItWork.techSupportExplanation'),
    },
    {
      icon: "/images/money.png",
      title: t('howItWork.licensedBroker'),
      text: t('howItWork.licensedBrokerExplanation'),
    },
    {
      icon: "/images/safety-safe.png",
      title: t('howItWork.safeBroker'),
      text: t('howItWork.safeBrokerExplanation'),
    },
  ];

  return (
    <section id="how-it-work">
      <Container>
        <Row>
          {list.map((m, i) => {
            return (
              <Col key={i} md={(i < 3 ? 4 : 6)} className="mb-4">
                <div className={"item-container " + (i === 0 ? 'dark' : '')}>
                  <div className="item">
                    <img src={m.icon} alt="" />
                    <h4>{m.title}</h4>
                    <p>{m.text}</p>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
}

export default observer(HowItWork);
